<file-upload
  [animation]="false"
  [formControl]="control"
  class="w-100"
  [accept]="accept"
  [multiple]="multiple"
  maxsize="25 MB"
>
  <ng-template #placeholder>
    <file-upload-drop-zone>
      {{ description }}
    </file-upload-drop-zone>
  </ng-template>

  <ng-template let-i="index" let-file="file" let-control="control" #item>
    <file-upload-list-item [index]="i" [file]="file" [control]="control">
      Entfernen
    </file-upload-list-item>
  </ng-template>
</file-upload>
