import { Component, Input, Optional, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { WrappedFormControlComponent } from 'src/app/components/shared/wrapped-form-control/wrapped-form-control.component';

@Component({
  selector: 'app-upload-files',
  standalone: true,
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  imports: [CommonModule, FileUploadModule, ReactiveFormsModule],
  encapsulation: ViewEncapsulation.None,
})
export class UploadFilesComponent extends WrappedFormControlComponent {
  @Input() description = 'Dateien hier ablegen';
  @Input() multiple = true;
  @Input() accept =
    'image/*|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/pdf';

  constructor(@Optional() public ngControl: NgControl) {
    super(ngControl);
  }
}
